:global .fixAntdAndMosonryGrid{
  @media (min-width: 1200px){
    .ant-col-xl-6{
      width:25%;
    }
  }
  @media (min-width: 992px){
    .ant-col-xl-6{
      width:25%;
    }
  }
  @media (min-width: 768px){
    .ant-col-md-8{
      width:33.333%;
    }
  }
  @media (min-width: 576px){
    .ant-col-sm-12{
      width:50%;
    }
  }
  .ant-col-xs-24{
    width:100%;
  }
}

.projectList {
  max-width: 1440px;
  margin: 0 auto;
  .couponBlock{
    height: 60px;
    width: 100%;
    //background-image: linear-gradient(270deg, #ECE6DD 0%, #FCFBF7 35%);
    // background: #FCFBF7;
    background: #0e8061;
    // border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 20px;
    line-height: 20px;
    margin-bottom: 20px;
    >.text{
      text-align: center;
      div{
        font-size: 14px;
        color: #fff;
        font-weight: 500;
        span {
          padding-left: 10px;
        }
        .couponCode {
          padding-left: 2px;
          text-decoration: underline;
          font-weight: 600;
        }
        .copy {
          cursor: pointer;
        }
      }
    }
    img{
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
    }
  }

  .products {
    margin-bottom: 20px;
    .productCategory {
      line-height: 30px;
      margin: 0 0 10px;
      font-size: 12px;
      color: rgba(74, 74, 74, 0.8);
      font-weight: bold;
    }

    .list {
      cursor: pointer;
      margin-bottom: 50px;
      .img {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #CFD1D7;
        }
      }

      .itemName {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0 5px 0;
        line-height: 20px;
      }

      .itemPrice {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
  .questionsBlock {
    font-family: PingFangSC-Medium;
    font-size: 16px;
    color: #000000;
    text-align: center;
    font-weight: 500;
    margin: 50px 0 20px;
  }
  .problem {
    //font-family: PingFangSC;
    // font-size: 14px;
    // color: #4A4A4A;
    // font-weight: 400;
    .questions, .noBorder{
      font-size: 15px;
      line-height: 25px;
      color: #4A4A4A;
      font-weight: 400;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 0.5px solid #E1E1E1;
      cursor: pointer;
      &.noBorder{
        border-color: transparent;
      }
      .click {
        width: 30px;
        text-align: center;
        cursor: pointer;
        font-size: 24px;
        color: #757575;
      }
      :global .iconfont {
        font-size: 12px;
      }
    }
    .answer {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 15px;
      border-bottom: 0.5px solid #E1E1E1;
    }
  }
}
@media screen and (max-width: 768px){
  .couponBlock{
    >.text{
      div{
        font-size: 12px !important;
        line-height: 18px;
      }
    }
  }
}

.projectInfo{
  max-width: 1440px;
  margin: 0 auto;
  .forBack{
    font-size: 12px;
    color: #757575;
    margin-bottom: 20px;
    div {
      display: inline-block;
      cursor: pointer;
      span {
        margin-right: 6px;
      }
    }
  }
  .project{
    .imgs{
      //width: 720px;
      .bigImg{
        width: 100%;
        position: relative;
        // width: 500px;
        //height: 720px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
          content: '';
          display: block;
          padding-bottom: 100%;
        }
        img{
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      ul{
        padding: 0;
        margin: 15px 0 15px 0;
        display: flex;
        justify-content: flex-start;
        li{
          width: 100px;
          height: 100px;
          list-style-type: none;
          margin-right: 15px;
          cursor: pointer;
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .info{
      //width: calc(100% - 750px);
      .name{
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        font-weight: bold;
      }
      .price{
        font-size: 14px;
        color: #4A4A4A;
        letter-spacing: 0;
        margin: 10px 0;
      }
      .information {
        display: inline-block;
        background: #f6e18d;
        color: rgba(0,0,0,.6);
        font-size: 12px;
        padding: 6px 10px 4px;
        margin: 10px auto;
        cursor: pointer;
      }
      .productOverview{
        margin: 20px 0;
        font-size: 14px;
        color: #757575;
        letter-spacing: 0;
      }
      .options{
        margin: 30px 0 0 0;
        .opt{
          font-size: 12px;
          color: #4A4A4A;
          font-weight: bold;
          margin-bottom: 5px;
        }
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 0;
          li{
            list-style-type: none;
            margin: 0 20px 10px 0;
            cursor: pointer;
            height: 36px;
            line-height: 36px;
            padding: 0 20px;
            background: #FFFFFF;
            color: #131415;
            border: 1px solid #DCDCDC;
            &.active{
              color: #200E32;
              border: 1px solid #200E32;
              font-weight: bold;
            }
          }
        }
      }
      .customization{
        width: 150px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: #000;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        margin: 40px 0 50px;
        cursor: pointer;
      }
      .textTitle{
        margin: 25px 0 0;
        font-size: 14px;
        font-weight: bold;
        color: #4A4A4A;
      }
      .description {
        margin: 5px 0;
        font-size: 14px;
        color: #4A4A4A;
        line-height: 22px;
        white-space: pre-wrap;
      }
    }
  }

  @media screen and (max-width: 768px){
    .project .imgs ul {
      margin: 10px 0 10px 0;
      li {
        width: 60px;
        height: 60px;
        margin-right: 10px;
      }
    }
    .project .info .name{
      margin-top: 10px;
    }
    .project .info .customization{
      width: 100%;
    }
  }

  .maybeLike{
    .title {
      font-size: 16px;
      color: #000000;
      font-weight: bold;
      text-align: center;
      margin: 160px 0 20px;
    }
    .list {
      cursor: pointer;
      margin-bottom: 50px;
      .img {
        width: 100%;
        position: relative;
        overflow: hidden;
        padding-bottom: 100%;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #CFD1D7;
        }
      }

      .itemName {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0 5px 0;
        line-height: 20px;
      }

      .itemPrice {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}

.qr {
  text-align: center;
  margin: -20px 0;
  img {
    width: 360px;
  }
  @media screen and (max-width: 768px){
    img {
      width: 300px;
    }
  }
  .qrtext {
    text-align: center;
    margin: 0 0 30px;
  }
  .qrok {
    display: inline-block;
    background: #000;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
  }
}
.informationModal{
  text-align: center;
  .informat{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .center{
      width: 420px;
      height: 280px;
      line-height: 280px;
      text-align: center;
      background: #F5F5F7;
      .img{
        margin: 0 auto;
        width: 180px;
        height: 180px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .left,.right{
      width: 20px;
      height: 100px;
      line-height: 100px;
      cursor: pointer;
    }
  }
  .informatTitle{
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    margin-top: 20px;
    font-weight: bold;
  }
  .time{
    font-size: 12px;
    color: #4A4A4A;
  }
  .button{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .addInformation{
      width: 118px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #fff;
      border: 1px solid #E1E1E1;
      font-size: 14px;
      color: #131415;
      cursor: pointer;
    }
    .edit{
      width: 118px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #000;
      font-size: 14px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}

@primary-color: #1AA77D;@normal-color: #1AA77D;@error-color: #CD292A;@link-color: #066DD5;@heading-color: #4A4A4A;@text-color: #4A4A4A;@description-color: #9B9B9B;@input-height-base: 45px;@box-shadow-base: 0 0 3px 0 #AAACB1;@menu-dark-item-active-bg: rgba(239,239,240,0.5);@menu-item-active-bg: rgba(239,239,240,0.5);@page-header-heading-title: 16px;